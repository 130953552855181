<template>
  <b-modal
    id="manage-campaign-products-modal"
    ref="manage-campaign-products-modal"
    title="Manage Campaign Product"
    centered
    hide-footer
    no-close-on-backdrop
    size="lg"
    @shown="doLoadData"
    @close="doCloseModal"
  >
    <b-overlay no-wrap :show="showOverlay" />

    <!-- Add Campaign Product To Media Plan -->
    <validation-observer ref="addCampaignProductForm">
      <b-form @submit.stop.prevent>
        <b-row>
          <b-col cols="6">
            <validation-provider
              #default="{ errors }"
              name="campaign product"
              rules="required"
            >
              <HeroVueSelect
                id="campaign-product-id"
                v-model="campaignProductId"
                label="Campaign Product"
                :options="campaignProductOptions"
                :require="true"
                :clearable="false"
                placeholder="Select Campaign Product"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <b-col cols="2" class="mt-2">
            <HeroButtonModal
              v-b-modal="'campaign-product-create-modal'"
              type="button"
              variant="success"
            >
              New
            </HeroButtonModal>
          </b-col>

          <b-col cols="4" class="mt-2">
            <HeroButtonAction type="button" variant="primary" @click="addCampaignProductToMediaPlan()">
              Add To Solution Plan
            </HeroButtonAction>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- Media Plan Table -->
    <validation-observer ref="MediaPlanForm">
      <b-form @submit.stop.prevent>
        <b-row>
          <b-col>
            <b-table-simple>
              <b-thead head-variant="light">
                <b-tr cols="8">
                  <b-th v-for="(column, idx) in columns" :key="idx">
                    {{ column.label }}
                  </b-th>
                </b-tr>
              </b-thead>

              <b-tbody>
                <b-tr v-for="(mediaPlan, mediaPlanIndex) in mediaPlans.products" :key="mediaPlanIndex" cols="4">

                  <!-- Campaign Product Name -->
                  <b-td class="text-left">
                    {{ mediaPlan.name }}
                  </b-td>

                  <!-- Action -->
                  <b-td>
                    <HeroButtonAction
                      type="button"
                      variant="danger"
                      @click="removeCampaignProductFromMediaPlan(mediaPlan.id)"
                    >
                      Remove
                    </HeroButtonAction>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <CampaignProductCreateModal @success="doLoadData('reload')" />
  </b-modal>
</template>

<script>
import moment from 'moment'
import store from '@/store'
import axios from '@axios'
import AxiosConfig from '@/services/AxiosConfigs'
import ErrorService from '@/services/ErrorService'
import MoneyService from '@/services/MoneyService'
import {
  BOverlay,
  BForm,
  BRow,
  BCol,
} from 'bootstrap-vue'
import SweetAlert from '@/services/SweetAlert'
import HeroButtonModal from '@/views/components/form/buttons/HeroButtonModal.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroInputNumber from '@/views/components/form/inputs/HeroInputNumber.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import HeroTextarea from '@/views/components/form/inputs/HeroTextarea.vue'
import CampaignProductCreateModal from '@/views/heroai/campaigns/components/view/campaign-product/CampaignProductCreateModal.vue'
import ProductModal from '@/models/Campaigns/ProductTable'

export default {
  components: {
    BOverlay,
    BForm,
    BRow,
    BCol,
    HeroButtonModal,
    HeroButtonAction,
    HeroInputText,
    HeroInputNumber,
    HeroVueSelect,
    HeroTextarea,
    CampaignProductCreateModal,
  },
  props: {
    mediaPlans: {
      type: Object,
      required: true,
    },
    buffer: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      // Overlay
      showOverlay: false,

      columns: [
        {
          label: 'Campaign Product',
          field: 'name',
        },
        {
          label: 'Action',
          field: 'remove',
        },
      ],

      // Data
      campaignId: this.$route.params.id ? this.$route.params.id : '',

      newProductFamilyId: '',
      newProductId: '',

      campaignProductId: '',
      campaignProductManagementFee: '',
      campaignProductTotalPrice: '',

      product: {
        total_price: 0.00,
      },

      campaignProduct: {},

      // Options
      backupCampaignProductOption: [],
      campaignProductOptions: [],
      walletBudgetOptions: [],

      // Service
      moneyService: MoneyService,
    }
  },
  watch: {
  },
  created() {
    this.product = { ...this.original }
  },
  async mounted() {
    this.$store.commit('ProductFamilyOptions/setDoUpdate', true)
    this.$store.commit('ProductOptions/setDoUpdate', true)

    await this.$store.dispatch('ProductFamilyOptions/fetchOptions')
    await this.$store.dispatch('ProductOptions/fetchOptions')

    // Wallet Budget
    const retainBudget = store.getters['heroAiCampaign/getRetainBudget']

    this.walletBudgetOptions.push({
      text: `Retain Budget - ${retainBudget}`,
      value: 'retain_budget',
      originalAmount: retainBudget,
      remainingAmount: retainBudget,
    })

    await this.$store.commit('SourceWalletBudgetOptions/setSourceId', this.$route.params.id)
    await this.$store.commit('SourceWalletBudgetOptions/setSourceType', 'Campaign')
    await this.$store.commit('SourceWalletBudgetOptions/setDoUpdate', true)
    await this.$store.dispatch('SourceWalletBudgetOptions/fetchOptions')

    const options = this.$store.getters['SourceWalletBudgetOptions/getOptions']

    options.forEach(item => {
      const option = {
        text: item.text,
        value: item.value,
        originalAmount: item.originalAmount,
        remainAmount: item.remainAmount,
      }
      this.walletBudgetOptions.push(option)
    })
  },
  methods: {
    doCloseModal() {
      this.product = { ...this.original }
      this.$emit('success')
      this.$refs['manage-campaign-products-modal'].hide()
    },

    async doLoadData(flag = '') {
      this.showOverlay = true

      await this.getCampaignProductOptions(flag)

      this.showOverlay = false
    },

    async getCampaignProductOptions(flag = '') {
      // ถ้าเปิด Modal เป็นครั้งแรก ให้ทำการ Back Up ข้อมูลเก็บเอาไว้ เพื่อให้การเพิ่มลบ Campaign Product ทำได้เร็วขึ้น
      if (this.backupCampaignProductOption.length === 0 || flag === 'reload') {
        const token       = this.$store.getters['heroAiAuthentications/sanctumToken']
        const axiosConfig = AxiosConfig.getJsonWithToken(token)

        const response = await axios.get(`/dropdown-options/campaign-product-options?campaignId=${this.campaignId}`, axiosConfig)

        this.backupCampaignProductOption = response.data
      }

      const campaignProductOptions    = this.backupCampaignProductOption
      const campaignProductIds        = []
      const newCampaignProductOptions = []

      // เก็บ Campaign Product Id ที่ใช้อยู่ใน Media Plan
      this.mediaPlans.products.forEach(mediaPlan => {
        campaignProductIds.push(mediaPlan.campaign_product_id)
      })

      // แสดงเฉพาะ Campaign Product ที่ไม่มีอยู่ใน Media Plan
      campaignProductOptions.forEach(campaignProductOption => {
        if (!campaignProductIds.includes(campaignProductOption.value)) {
          newCampaignProductOptions.push(campaignProductOption)
        }
      })

      this.campaignProductOptions = newCampaignProductOptions
    },

    async addCampaignProductToMediaPlan() {
      const validationResult = await this.$refs.addCampaignProductForm.validate()

      if (validationResult) {
        const campaignProductOption = this.campaignProductOptions.find(option => option.id === this.campaignProductId)

        const newMediaPlan = new ProductModal()

        newMediaPlan.name                = campaignProductOption.name
        newMediaPlan.wallet_budget_id    = 'retain_budget'
        newMediaPlan.product_family_id   = campaignProductOption.product_family_id
        newMediaPlan.product_family_name = campaignProductOption.product_family_name
        newMediaPlan.product_id          = campaignProductOption.product_id
        newMediaPlan.campaign_product_id = this.campaignProductId
        newMediaPlan.total_price         = 0.00
        newMediaPlan.management_fee      = 0.00
        newMediaPlan.from_cycle          = this.mediaPlans.from_cycle
        newMediaPlan.to_cycle            = this.mediaPlans.to_cycle
        newMediaPlan.is_new_product      = true

        this.mediaPlans.products.push(newMediaPlan)
        store.commit('heroAiCampaign/setCampaignProducts', this.mediaPlans)

        this.campaignProductId = ''
        await this.doLoadData()
      }
    },

    async removeCampaignProductFromMediaPlan(mediaPlanId) {
      const newMediaPlans = []

      this.mediaPlans.products.forEach(mediaPlan => {
        if (mediaPlan.id !== mediaPlanId) {
          newMediaPlans.push(mediaPlan)
        }
      })

      this.mediaPlans.products = newMediaPlans
      await this.doLoadData()
    },

    setManagementFee() {
      // eslint-disable-next-line quotes
      return `required|between:0,100`
    },
  },
}
</script>
