var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"observer"},[_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('HeroDisplayText',{attrs:{"id":"amount-of-cycles","label":"Amount of Cycles"},model:{value:(_vm.fillMedia.amount_of_cycles),callback:function ($$v) {_vm.$set(_vm.fillMedia, "amount_of_cycles", $$v)},expression:"fillMedia.amount_of_cycles"}})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('HeroDisplayText',{attrs:{"id":"buffer","value":_vm.campaign_products.buffer.toString(),"label":"Buffer(%)"}})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"full media plan link","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroInputText',{attrs:{"id":"media-plan-link","label":"Full Media Plan Link","required":true,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},model:{value:(_vm.fillMedia.media_plan_link),callback:function ($$v) {_vm.$set(_vm.fillMedia, "media_plan_link", $$v)},expression:"fillMedia.media_plan_link"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('HeroDisplayText',{attrs:{"id":"pricing-model","label":"Pricing Model"},model:{value:(_vm.fillMedia.pricing_model),callback:function ($$v) {_vm.$set(_vm.fillMedia, "pricing_model", $$v)},expression:"fillMedia.pricing_model"}})],1)],1)],1)],1),_c('b-row',[_c('b-table-simple',{attrs:{"hover":"","large":"","caption-top":"","striped":"","responsive":""}},[_c('colgroup',[_c('col'),_c('col')]),_c('colgroup',[_c('col'),_c('col'),_c('col')]),_c('colgroup',[_c('col'),_c('col')]),_c('b-thead',{attrs:{"head-variant":"light"}},[_c('b-tr',_vm._l((_vm.columns),function(column,idx){return _c('b-th',{key:idx},[_vm._v(" "+_vm._s(column.label)+" ")])}),1)],1),_vm._l((_vm.products),function(childData,idx){return _c('b-tbody',{key:idx},[_c('b-tr',[_c('b-td',{staticClass:"text-left"},[_c('img',{attrs:{"src":_vm.getImage(childData.product_family_name),"alt":"","width":"15","height":"15"}}),_vm._v(" "+_vm._s(childData.name)+" ")]),_c('b-td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.formatMoney(childData.total_price))+" ")]),_c('b-td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.formatMoney(childData.ads_budget))+" ")]),_c('b-td',[_vm._v(" "+_vm._s(parseFloat(childData.management_fee))+"% ")]),_c('b-td',[_c('validation-provider',{attrs:{"name":"from cycle","rules":_vm.setToCycle(),"vid":("vid-from-cycle-" + idx)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroInputNumber',{attrs:{"id":("from-cycle-" + idx),"required":true,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},model:{value:(childData.from_cycle),callback:function ($$v) {_vm.$set(childData, "from_cycle", $$v)},expression:"childData.from_cycle"}})]}}],null,true)})],1),_c('b-td',[_c('validation-provider',{attrs:{"name":"to cycle","rules":_vm.setToCycle(),"vid":("vid-to-cycle-" + idx)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroInputNumber',{attrs:{"id":("to-cycle-" + idx),"required":true,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},model:{value:(childData.to_cycle),callback:function ($$v) {_vm.$set(childData, "to_cycle", $$v)},expression:"childData.to_cycle"}})]}}],null,true)})],1),_c('b-td',[_c('validation-provider',{attrs:{"name":"KPI per cycle","rules":"required","vid":("vid-kpi-per-cycle-" + idx)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroInputNumber',{attrs:{"id":("kpi-cycle-" + idx),"required":true,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},on:{"input":function($event){return _vm.updateCPKPIUnit(idx)}},model:{value:(childData.kpi),callback:function ($$v) {_vm.$set(childData, "kpi", $$v)},expression:"childData.kpi"}})]}}],null,true)})],1),_c('b-td',{attrs:{"width":"20%"}},[_c('validation-provider',{attrs:{"name":"KPI unit","rules":"required","vid":("vid-kpi-unit-" + idx)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":("kpi-unit-" + idx),"options":_vm.kpiOptions,"clearable":false,"required":true,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},model:{value:(childData.kpi_unit_id),callback:function ($$v) {_vm.$set(childData, "kpi_unit_id", $$v)},expression:"childData.kpi_unit_id"}})]}}],null,true)})],1),_c('b-td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.formatMoney(childData.cost_per_unit))+" ")]),_c('b-td',[_c('div',{staticClass:"span span:hover"},[_vm._v(" "+_vm._s(childData.comment)+" ")])])],1)],1)}),_c('b-tfoot',[_c('b-tr',[_c('b-td',{attrs:{"variant":"primary"}},[_vm._v(" Total ")]),_c('b-td',{staticClass:"text-right",attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.formatMoney(_vm.campaign_products.total.net_budget))+" ")]),_c('b-td',{staticClass:"text-right",attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.formatMoney(_vm.campaign_products.total.ads_budget))+" ")]),_c('b-td',{staticClass:"text-center",attrs:{"colspan":"7","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.kpiSummary)+" ")])],1)],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }