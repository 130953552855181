<template>
  <b-card>
    <validation-observer ref="observer">
      <b-form @submit.stop.prevent>
        <b-row>
          <b-col cols="12" md="4">
            <HeroDisplayText
              id="amount-of-cycles"
              v-model="fillMedia.amount_of_cycles"
              label="Amount of Cycles"
            />
          </b-col>
          <b-col cols="12" md="4">
            <HeroDisplayText
              id="buffer"
              :value="campaign_products.buffer.toString()"
              label="Buffer(%)"
            />
          </b-col>
          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="full media plan link"
              rules="required|url"
            >
              <HeroInputText
                id="media-plan-link"
                v-model="fillMedia.media_plan_link"
                label="Full Media Plan Link"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col cols="12" md="4">
            <HeroDisplayText
              id="pricing-model"
              v-model="fillMedia.pricing_model"
              label="Pricing Model"
            />
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <b-row>
      <b-table-simple
        hover
        large
        caption-top
        striped
        responsive
      >
        <!-- <caption>Items sold in August, grouped by Country and City:</caption> -->
        <colgroup><col><col></colgroup>
        <colgroup><col><col><col></colgroup>
        <colgroup><col><col></colgroup>
        <b-thead head-variant="light">
          <b-tr>
            <b-th v-for="(column, idx) in columns" :key="idx">
              {{ column.label }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody v-for="(childData, idx) in products" :key="idx">
          <b-tr>
            <b-td class="text-left">
              <img :src="getImage(childData.product_family_name)" alt="" width="15" height="15">
              {{ childData.name }}
            </b-td>
            <b-td class="text-right">
              {{ formatMoney(childData.total_price) }}
            </b-td>
            <b-td class="text-right">
              {{ formatMoney(childData.ads_budget) }}
            </b-td>
            <b-td>
              {{ parseFloat(childData.management_fee) }}%
            </b-td>
            <b-td>
              <validation-provider
                #default="{ errors }"
                name="from cycle"
                :rules="setToCycle()"
                :vid="`vid-from-cycle-${idx}`"
              >
                <HeroInputNumber
                  :id="`from-cycle-${idx}`"
                  v-model="childData.from_cycle"
                  :required="true"
                  :state="errors.length > 0 ? false : null "
                  :invalid-feedback="errors[0]"
                />
              </validation-provider>
            </b-td>
            <b-td>
              <validation-provider
                #default="{ errors }"
                name="to cycle"
                :rules="setToCycle()"
                :vid="`vid-to-cycle-${idx}`"
              >
                <HeroInputNumber
                  :id="`to-cycle-${idx}`"
                  v-model="childData.to_cycle"
                  :required="true"
                  :state="errors.length > 0 ? false : null "
                  :invalid-feedback="errors[0]"
                />
              </validation-provider>
            </b-td>
            <b-td>
              <validation-provider
                #default="{ errors }"
                name="KPI per cycle"
                rules="required"
                :vid="`vid-kpi-per-cycle-${idx}`"
              >
                <HeroInputNumber
                  :id="`kpi-cycle-${idx}`"
                  v-model="childData.kpi"
                  :required="true"
                  :state="errors.length > 0 ? false : null "
                  :invalid-feedback="errors[0]"
                  @input="updateCPKPIUnit(idx)"
                />
              </validation-provider>
            </b-td>
            <b-td width="20%">
              <validation-provider
                #default="{ errors }"
                name="KPI unit"
                rules="required"
                :vid="`vid-kpi-unit-${idx}`"
              >
                <HeroVueSelect
                  :id="`kpi-unit-${idx}`"
                  v-model="childData.kpi_unit_id"
                  :options="kpiOptions"
                  :clearable="false"
                  :required="true"
                  :state="errors.length > 0 ? false : null "
                  :invalid-feedback="errors[0]"
                />
              </validation-provider>
            </b-td>
            <b-td class="text-right">
              {{ formatMoney(childData.cost_per_unit) }}
            </b-td>
            <b-td>
              <div class="span span:hover">
                {{ childData.comment }}
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
        <b-tfoot>
          <b-tr>
            <b-td variant="primary">
              Total
            </b-td>
            <b-td class="text-right" variant="primary">
              {{ formatMoney(campaign_products.total.net_budget) }}
            </b-td>
            <b-td class="text-right" variant="primary">
              {{ formatMoney(campaign_products.total.ads_budget) }}
            </b-td>
            <b-td class="text-center" colspan="7" variant="primary">
              {{ kpiSummary }}
            </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
    </b-row>

  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTableSimple,
  BTh,
  BTr,
  BTd,
  BThead,
  BTbody,
} from 'bootstrap-vue'
import HeroDisplayText from '@/views/components/form/displays/HeroDisplayText.vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroInputNumber from '@/views/components/form/inputs/HeroInputNumber.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  required,
} from '@validations'
import Store from '@/store/index'
import DataFormatService from '@/services/DataFormatService'
import Constant from '@/utils/Constant'
import SweetAlert from '@/services/SweetAlert'
import AxiosService from '@/services/AxiosService'
import ErrorService from '@/services/ErrorService'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTableSimple,
    BTh,
    BTr,
    BTd,
    BThead,
    BTbody,
    HeroDisplayText,
    HeroInputText,
    HeroVueSelect,
    HeroInputNumber,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    hasChange: {
      type: Boolean,
      default: true,
    },
    fillMedia: {
      type: Object,
      default: null,
    },
    campaign_products: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      required,
      kpi_cycle: '',
      kpiOptions: [],
      columns: [
        {
          label: 'Product Name',
          field: 'name',
        },
        {
          label: 'Total Price',
          field: 'total_price',
        },
        {
          label: 'Ads.Budget',
          field: 'ads_budget',
        },
        {
          label: 'Mgmt Fee + Buffer',
          field: 'management_fee',
        },
        {
          label: 'From Cycle',
          field: 'from_cycle',
        },
        {
          label: 'To Cycle',
          field: 'to_cycle',
        },
        {
          label: 'KPI Per Cycle',
          field: 'kpi',
          type: 'text',
        },
        {
          label: 'KPI Unit',
          field: 'kpi_unit_id',
        },
        {
          label: 'cost per unit',
          field: 'cost_per_unit',
        },
        {
          label: 'Comment',
          field: 'comment',
        },
      ],
      products: [
        {
          name: '',
          net_budget: '',
          ads_budget: '',
          from_cycle: '',
          to_cycle: '',
          management_fee: '',
          kpi_cycle: '',
          kpi_unit: '',
          cp_kpi_unit: '',
          type: '',
        },
      ],
      total: {
        net_budget: '',
        ads_budget: '',
        leads: '',
      },
    }
  },
  computed: {
    campaignProductsStore() {
      return Store.getters['heroAiCampaign/getCampaignProducts']
    },
    kpiSummary() {
      const GrandTotalArray = []
      this.campaign_products.products.forEach(products => {
        const KPIUnitIndex = GrandTotalArray.findIndex(x => x.id === products.kpi)
        const KPIPerCycle = parseInt(products.kpi, 10)
        if (KPIPerCycle > 0) {
          if (KPIUnitIndex > -1) {
            GrandTotalArray[KPIUnitIndex].value += KPIPerCycle
          } else {
            GrandTotalArray.push({
              id: products.kpi_unit_id,
              value: KPIPerCycle,
            })
          }
        }
      })
      const KPISummary = []
      let kpiSummaryText = ''
      if (GrandTotalArray && GrandTotalArray.length > 0) {
        GrandTotalArray.forEach(kpidata => {
          const kpiUnitName = this.kpiOptions.filter(x => x.value === kpidata.id)
          if (kpiUnitName && kpiUnitName.length > 0) {
            KPISummary.push(`${kpidata.value} ${kpiUnitName[0].text}`)
          }
        })
        kpiSummaryText = KPISummary.join(' || ')
      }
      return kpiSummaryText
    },
  },
  watch: {
    fillMedia: {
      handler(newValue) {
        Store.commit('heroAiCampaign/setFillMedia', newValue)
      },
      deep: true,
    },
    hasChange: {
      handler() {
        const campaignProduct = Store.getters['heroAiCampaign/getCampaignProducts']
        this.products = campaignProduct.products
      },
    },
    campaignProductsStore: {
      handler(newValue) {
        this.products = newValue.products
      },
    },
  },
  mounted() {
    this.getKpiUnitDropdownList()
  },
  created() {
    this.formatMoney = DataFormatService.formatMoney
    const campaignProduct = Store.getters['heroAiCampaign/getCampaignProducts']
    this.products = campaignProduct.products
  },
  methods: {
    getImage(type) {
      const images = require.context('@/assets/images/social-icons', false, /\.png$/)
      let imageURL = images('./Hero.png')
      if (type === 'Hero Google' || type === 'AdWords') {
        imageURL = images('./google.png')
      } else if (type === 'Facebook' || type === 'Hero Facebook') {
        imageURL = images('./facebook.png')
      } else if (type === 'Line' || type === 'Line Ads' || type === 'Hero Line') {
        imageURL = images('./line.png')
      } else if (type === 'Hero Call Tracking' || type === 'Call Tracking') {
        imageURL = images('./mobile-phone.png')
      } else if (type === 'Direct') {
        imageURL = images('./landing-page.png')
      } else if (type === 'Instagram' || type === 'Hero Instagram') {
        imageURL = images('./instagram.png')
      } else if (type === 'YouTube' || type === 'Hero Youtube') {
        imageURL = images('./youtube.png')
      } else if (type === 'Messenger' || type === 'Hero Chatbot') {
        imageURL = images('./messenger.png')
      } else if (type === 'Email' || type === 'Hero Mail') {
        imageURL = images('./gmail.png')
      } else if (type === 'Linkedin' || type === 'Hero LinkedIn') {
        imageURL = images('./linkedin.png')
      } else if (type === 'SMS') {
        imageURL = images('./sms.png')
      } else if (type === 'Taboola' || type === 'Hero Taboola') {
        imageURL = images('./taboola.png')
      } else if (type === 'Twitter' || type === 'Hero Twitter') {
        imageURL = images('./twitter.png')
      } else if (type === 'Whatsapp') {
        imageURL = images('./whatsapp.png')
      }
      return imageURL
    },
    updateCPKPIUnit(idx) {
      const currentProduct = this.products[idx]
      let cpKPIUnit = 0
      if (currentProduct.kpi && currentProduct.kpi > 0) {
        cpKPIUnit = parseFloat(parseFloat(currentProduct.net_budget) / parseFloat(currentProduct.kpi)).toFixed(2)
      }
      this.products[idx].cost_per_unit = cpKPIUnit

      const newCampaignProduct = {
        ...this.campaign_products,
        products: this.products,
      }

      Store.commit('heroAiCampaign/setCampaignProducts', newCampaignProduct)
    },
    async getKpiUnitDropdownList() {
      try {
        const qparam = {
          slug: 'kpi_units',
        }
        const response = await AxiosService.get(Constant.apiURL.salesforceCampaignDropdown, qparam)
        this.kpiOptions = (response.data)
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }
        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }
      }
    },
    setToCycle() {
      const fromCycle = this.campaign_products.from_cycle
      const toCycle   = this.campaign_products.to_cycle
      return `required|between:${fromCycle},${toCycle}`
    },
  },
}
</script>
<style>
  .span {
    width: 100px; /* can be 100% ellipsis will happen when contents exceed it */
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .span:hover {
    white-space: normal;
    /* or:
    width: auto
    */
  }
</style>
